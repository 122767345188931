
// Define a secret key for encryption/decryption
const SECRET_KEY = '9f3f2cea26ccd91a343edd63d87200e3ef71d4438619a3b3c67d094dbfa70c03';

// Encrypt a value
const encrypt = (value: any): string => {
    return JSON.stringify(value);
};

// Decrypt a value
const decrypt = (cipherText: string): any => {
    return JSON.parse(cipherText);
};

// Set an item in localStorage with encryption
export const setItem = (key: string, value: any): void => {
    try {
        localStorage.setItem(key, value);
    } catch (error) {
    }
};

// Get an item from localStorage with decryption
export const getItem = (key: string): any | null => {
    try {
        return localStorage.getItem(key);
    } catch (error) {
        return null;
    }
};

// Remove an item from localStorage
export const removeItem = (key: string): void => {
    try {
        localStorage.removeItem(key);
    } catch (error) {
    }
};

// Clear all items from localStorage
export const clearStorage = (): void => {
    try {
        localStorage.clear();
    } catch (error) {
    }
};

// Clear specific items from localStorage
export const clearSpecificItems = (keys: string[]): void => {
    try {
        keys.forEach(key => localStorage.removeItem(key));
    } catch (error) {
    }
};
