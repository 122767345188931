import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const TopHeader = () => {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const handleLogout = () => {
        localStorage.removeItem('access_token');
        localStorage.clear();
        navigate('/');
    };
    

    return (
        <div className={`numo-outer-btn-200 rounded-full hover:nm-flat-gray-200-lg leading-5 px-4 py-2 font-normal tracking-widest text-sm transition duration-200 ease-in-out transform fixed top-5 right-5 z-50 w-28 ${isOpen ? '' : ''}`}>
            <div className='flex items-center justify-between'>
                <div>WioCv</div>
                <div onClick={toggleMenu} className='cursor-pointer'>
                    <svg width="24" height="24" viewBox="0 0 24 24">
                        <path d="M3 6h18M3 12h18m-18 6h18" stroke="currentColor" strokeWidth="2" />
                    </svg>
                </div>
            </div>
            {isOpen && (
                <div className='text-right py-2 pt-3 text-sm absolute top-12 right-0 numo-outer-btn-200 rounded-lg hover:nm-flat-gray-200-lg leading-5 px-4  font-normal tracking-widest   transition duration-200 ease-in-out transform'>
                    <ul className='flex flex-col gap-2 cursor-pointer pb-2'>
                        {/* <li>Profile</li>
                        <li>Resumes</li> */}
                        <li onClick={() => navigate('/')}>Home</li>
                        <li onClick={() => navigate('/create-cv')}>Create CV</li>
                        <li onClick={() => navigate('/view-cv')}>View CV</li>
                        <li onClick={handleLogout}>Logout</li>
                    </ul>
                </div>
            )}
        </div>
    );
};

export default TopHeader;