// src/AppRouter.tsx
import React from 'react';
import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';
import App from '../AuthScreen';
import CreateResume from '../pages/resume/CreateResume';
import ViewCv from '../pages/resume/ViewCv';
import Home from '../pages/home/Home';
import AuthScreen from '../pages/auth/Auth';

// Modify the isAuthenticated function to return a boolean
const isAuthenticated = (): boolean => {
    return localStorage.getItem('isLoggedIn') === 'true';
};

// Update the AuthGuard component
const AuthGuard: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    if (!isAuthenticated()) {
        return <Navigate to="/login" replace />;
    }
    return <>{children}</>;
};

// Add a new component for the root route
const RootRoute: React.FC = () => {
    if (isAuthenticated()) {
        return <Navigate to="/home" replace />;
    }
    return <App />;
};

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home />,
    },
    {
        path: "/login",
        element: <AuthScreen />,
    },
    {
        path: "/home",
        element: (
            <AuthGuard>
                <Home />
            </AuthGuard>
        ),
    },
    {
        path: "create-cv",
        element: (
            <AuthGuard>
                <CreateResume />
            </AuthGuard>
        ),
        children: [
            // Additional child routes can be added here
        ],
    },
    {
        path: "view-cv",
        element: (
            <AuthGuard>
                <ViewCv />
            </AuthGuard>
        ),
        children: [
            // Additional child routes can be added here
        ],
    },
]);

export const navigateTo = (path: string) => {
    router.navigate(path);
};

const AppRouter: React.FC = () => {
    return <RouterProvider router={router} />;
};

export default AppRouter;
