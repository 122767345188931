import apiClient from './instance';

export async function postRequestOtp(email: string) {
    try {
        const response = await apiClient.post('/request_otp', {
            email,
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

export async function postVerifyOtp(otp: string, hash: string) {
    try {
        const response = await apiClient.post('/verify_otp', { "otp": otp, "otp_hash": hash });
        return response.data;
    } catch (error) {
        throw error;
    }
}

