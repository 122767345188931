import BottomTab from "../../components/BottomTab";
import { useNavigate } from "react-router-dom";
import TopHeader from "../../components/topHeader";
const Home = () => {
    const navigate = useNavigate();
    return (
        <>
            <TopHeader />
            <div className="w-full mx-auto px-4">
                <div className="flex flex-col items-center justify-center h-screen">

                    <h1 className="text-4xl font-bold mb-4 text-center">Welcome to WioCV</h1>
                    <p className="text-md mb-6 text-center">Create your professional CV <br /> <strong className="text-blue-500 font-semibold capitalize underline">easily and quickly</strong></p>
                    <button
                        className="w-52 rounded-full h-10 numo-outer-btn-200 hover:nm-flat-gray-200-lg leading-5 px-4 py-1 inline-flex items-center justify-center font-normal tracking-widest text-sm transition duration-200 ease-in-out transform"
                        onClick={() => navigate('/create-cv')}
                    >
                        Create Resume
                    </button>
                    <BottomTab />
                </div>
            </div>
        </>
    )
}

export default Home;