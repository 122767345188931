import React, { useRef, useEffect, useState, useImperativeHandle } from 'react';
import { jsPDF } from "jspdf";
import Button from '../../../components/util/Button';
import apiClient from '../../../service/instance';
import { ResumeDataResponse } from '../../../constants/types';
import FullLoader from '../../../components/util/FullLoader';

interface PersonalInfo {
    fullName: string;
    title: string;
    phoneNumbers: PhoneNumber[];
    email: string;
    website: string;
    profile: string;
    languages: Language[];
    currentPosition: string;
    dob: { day: string; month: string; year: string };
    address: string;
    country: string;
    links: Link[];
}

interface Link {
    title: string;
    url: string;
}

interface PhoneNumber {
    number: string;
    countryCode: string;
}

interface Skill {
    name: string;
}

interface Education {
    institution: string;
    degree: string;
    date: string;
    startMonth: string;
    startYear: string;
    endMonth: string;
    endYear: string;
    fieldOfStudy: string;
}

interface Project {
    name: string;
    role: string;
    date: string;
    description: string;
}

interface Experience {
    company: string;
    position: string;
    date: string;
    description: string;
    startMonth: string;
    startYear: string;
    endMonth: string;
    endYear: string;
    isCurrentCompany: boolean;
}

interface Language {
    language: string;
    level: string;
}

interface Props {
    downloadPdf?: string;
    design?: string;
    selectedFont?: string;
    projectPosition?: boolean;
}

const Professional = (props: Props, ref: any) => {
    const contentRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [personalInfo, setPersonalInfo] = useState<PersonalInfo | null>(null);
    const [skills, setSkills] = useState<Skill[]>([]);
    const [education, setEducation] = useState<Education[]>([]);
    const [projects, setProjects] = useState<Project[]>([]);
    const [experience, setExperience] = useState<Experience[]>([]);
    const [summary, setSummary] = useState<string>('');

    const [resumeData, setResumeData] = useState<any>(null);
    const [design, setDesign] = useState<string>('professional');
    const [selectedFont, setSelectedFont] = useState<string>('Arial, sans-serif');

    useImperativeHandle(ref, () => ({
        downloadFile: downloadPDF
    }));

    useEffect(() => {
        getResumeData();
    }, []);

    useEffect(() => {
        setSelectedFont(props.selectedFont || 'Arial, sans-serif');
    }, [props.selectedFont]);


    const getResumeData = async () => {
        setLoading(true);
        try {
            const response: any = await apiClient.get('get_all_resume_data');

            if (response.data.status) {
                const resumeDataArray = response.data.resume_data;
                return setResumeDataToState(resumeDataArray);
            }
        } catch (error) {
            setLoading(false);
            return false;
        } finally {
            setLoading(false);
        }
    };

    const setResumeDataToState = (resumeDataArray: any) => {
        const personalInfoData = resumeDataArray.find((item: any) => item.cgmu_type === 'personalinfo');
        const summaryData = resumeDataArray.find((item: any) => item.cgmu_type === 'summary');
        const skillsData = resumeDataArray.find((item: any) => item.cgmu_type === 'skills');
        const educationData = resumeDataArray.find((item: any) => item.cgmu_type === 'education');
        const experienceData = resumeDataArray.find((item: any) => item.cgmu_type === 'experiance');
        const projectsData = resumeDataArray.find((item: any) => item.cgmu_type === 'projects');

        if (personalInfoData) {
            setPersonalInfo(JSON.parse(personalInfoData.cgmu_data));
        }
        if (summaryData) {
            setSummary(JSON.parse(summaryData.cgmu_data));
        }
        if (skillsData) {
            setSkills(JSON.parse(skillsData.cgmu_data));
        }
        if (educationData) {
            setEducation(JSON.parse(educationData.cgmu_data));
        }
        if (experienceData) {
            setExperience(JSON.parse(experienceData.cgmu_data));
        }
        if (projectsData) {
            setProjects(JSON.parse(projectsData.cgmu_data));
        }

        setResumeData(resumeDataArray);
        if (resumeDataArray.length > 0) {
            setLoading(false);
            return true;
        }
        return false;
    }

    const downloadPDF = async () => {
        const resumeData = await getResumeData();
        if (!resumeData) {
            return false;
        }
        else {
            const doc = new jsPDF('p', 'px', 'a4');
            const content = contentRef.current;
            if (content) {
                // Optional - Add styling to the PDF
                doc.setFontSize(6);

                // Add HTML content to PDF
                doc.html(content, {
                    callback: function (doc) {
                        doc.save(`${personalInfo?.fullName}-Resume.pdf`);
                    },
                    // x: 10,
                    // y: 10,
                    margin: 10,
                    width: 2480,
                    autoPaging: 'text',
                    html2canvas: {
                        scale: 0.5, // Adjust scale to 1 for normal size (can be 2 for high quality)
                    },
                });
            }
        }
    }

    if (!resumeData) {
        return (<FullLoader show={loading} />);
    }

    return (
        <>
            <FullLoader show={loading} />
            <div style={{ width: '884px', minHeight: '1280px', backgroundColor: 'white', border: '1px solid lightgray', margin: '20px auto', padding: '20px', }}>
                <div id="content-to-print" ref={contentRef} style={{
                    scale: 1,
                    width: '834px',
                    margin: '0 auto',
                    maxWidth: '100%'
                }}>
                    <table style={{
                        width: '844px',
                        fontFamily: selectedFont,
                        fontSize: '16px',
                        lineHeight: 1.6,
                        borderCollapse: 'collapse',
                        letterSpacing: '0.01em',
                        // height: '1222px', 
                    }}>
                        <tbody>
                            <tr>
                                <td style={{ width: '40%', verticalAlign: 'top', paddingRight: '15px', borderRight: '1px solid lightgray' }}>
                                    <table style={{ width: '100%' }}>
                                        <tbody>
                                            {personalInfo && (
                                                <tr>
                                                    <td style={{ padding: '5px' }}>
                                                        {personalInfo.fullName && <h1 style={{ fontSize: '38px', fontWeight: '400', margin: 0, lineHeight: 1, marginBottom: '10px' }}>{personalInfo.fullName}</h1>}
                                                        {personalInfo.currentPosition && <div style={{ margin: 0, fontSize: '16px', fontWeight: 'bold' }}>{personalInfo.currentPosition}</div>}
                                                        {personalInfo.title && <div style={{ margin: 0, fontSize: '16px', fontWeight: 'bold' }}>{personalInfo.title}</div>}
                                                        {personalInfo.dob && (
                                                            <div style={{ margin: 0, fontSize: '13px' }}>
                                                                {/* DOB: {personalInfo.dob.day}/{personalInfo.dob.month}/{personalInfo.dob.year} */}
                                                                {personalInfo.address && <span style={{ marginLeft: '0px' }}>{personalInfo.address},</span>}
                                                                {personalInfo.country && <span style={{ marginLeft: '5px' }}>{personalInfo.country}</span>}
                                                                {/* {personalInfo.dob.year && <span style={{ marginLeft: '5px' }}>({new Date().getFullYear() - Number(personalInfo.dob.year)} years old)</span>} */}
                                                            </div>
                                                        )}
                                                        <div style={{ margin: '10px 0 0px 0', fontSize: '14px' }}>
                                                            {personalInfo.phoneNumbers && personalInfo.phoneNumbers.length > 0 && personalInfo.phoneNumbers.map((phone, index) => (
                                                                <React.Fragment key={index}>
                                                                    {phone.countryCode && phone.number && (
                                                                        <div style={{ margin: 0 }}>
                                                                            {'+' + phone.countryCode} {phone.number}
                                                                            {index < personalInfo.phoneNumbers.length - 1 && <br />}
                                                                        </div>
                                                                    )}
                                                                </React.Fragment>
                                                            ))}
                                                        </div>
                                                        <div style={{ margin: '0px 0 10px 0', fontSize: '14px' }}>
                                                            {personalInfo.email && <div style={{ margin: 0 }}>{personalInfo.email}</div>}
                                                            {personalInfo.website && <div style={{ margin: 0 }}>{personalInfo.website}</div>}
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                            {summary && (
                                                <tr>
                                                    <td style={{ padding: '5px', }}>
                                                        <div style={{ fontSize: '18px', margin: 0, fontWeight: 'bold', borderBottom: '1px solid lightgray', paddingBottom: '8px' }}>PROFILE </div>
                                                        <div style={{ margin: '10px 0', fontSize: '13px' }}>{summary}</div>
                                                    </td>
                                                </tr>
                                            )}
                                            {skills && skills.length > 0 && (
                                                <tr>
                                                    <td style={{ padding: '5px', width: '50%' }}>
                                                        <div style={{ fontSize: '18px', margin: 0, fontWeight: 'bold', borderBottom: '1px solid lightgray', paddingBottom: '8px' }}>SKILLS </div>
                                                        <table style={{ margin: '10px 0 10px 0px', padding: 0, fontSize: '14px', listStyleType: 'none', width: '100%' }}>
                                                            <tbody>
                                                                <tr>
                                                                    <td style={{ width: '50%', verticalAlign: 'text-top' }}>
                                                                        {skills.slice(0, Math.ceil(skills.length / 2)).map((skill, index) => (

                                                                            <div className='flex items-center justify-start' key={index}>
                                                                                <div className='text-gray-500 mr-2 text-[18px]'>&#x2022;</div>
                                                                                {skill.name}
                                                                            </div>

                                                                        ))}
                                                                    </td>

                                                                    <td style={{ width: '50%', verticalAlign: 'text-top' }}>
                                                                        {skills.slice(Math.ceil(skills.length / 2)).map((skill, index) => (
                                                                            <div className='flex items-center justify-start' key={index}>
                                                                                <div className='text-gray-500 mr-2 text-[18px]'>&#x2022;</div>
                                                                                {skill.name}
                                                                            </div>
                                                                        ))}
                                                                    </td>
                                                                </tr>

                                                            </tbody>
                                                        </table>
                                                    </td>

                                                </tr>
                                            )}
                                            {/* Hide education section if projects array is empty */}
                                            {education && education.length > 0 && projects && projects.length > 0 && (
                                                <tr>
                                                    <td style={{ padding: '5px', }}>
                                                        <div style={{ fontSize: '18px', margin: 0, fontWeight: 'bold', borderBottom: '1px solid lightgray', paddingBottom: '8px' }}>EDUCATION </div>
                                                        {education.slice().reverse().map((edu, index) => (
                                                            <div key={index} style={{ margin: '10px 0', fontSize: '13px' }}>
                                                                <div style={{ margin: '0 0 10px 0', }}>
                                                                    <div style={{ fontWeight: 'bold', fontSize: '14px' }}>{edu.institution}</div>
                                                                    {edu.degree &&
                                                                        <div style={{ fontSize: '12px', display: 'flex', alignItems: 'center' }}>
                                                                            <span>{edu.degree}</span> {' '}
                                                                            {edu.fieldOfStudy && <span style={{ fontSize: '12px', display: 'inline-block', paddingLeft: '5px' }}>{' - ' + edu.fieldOfStudy}</span>}
                                                                        </div>
                                                                    }
                                                                    <div style={{ fontSize: '12px', fontStyle: 'italic' }}>
                                                                        {edu.startYear && `${edu.startMonth} ${edu.startYear} `}
                                                                        {edu.endYear && ` - ${edu.endMonth} ${edu.endYear}`}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </td>
                                                </tr>
                                            )}
                                            {personalInfo && personalInfo.languages && personalInfo.languages.length > 0 && (
                                                <tr>
                                                    <td style={{ padding: '5px', verticalAlign: 'text-top' }}>
                                                        <div style={{ fontSize: '18px', margin: 0, fontWeight: 'bold', borderBottom: '1px solid lightgray', paddingBottom: '8px' }}>LANGUAGES </div>
                                                        <ul style={{ margin: '10px 0 0px 0px', padding: 0, fontSize: '14px', listStyleType: 'none' }}>
                                                            {personalInfo.languages.map((lang, index) => (
                                                                <li key={index} style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <div style={{ color: 'gray', marginRight: '8px', fontSize: '18px' }}>&#x2022;</div>
                                                                    {lang.language} - {lang.level}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </td>
                                                </tr>
                                            )}
                                            {personalInfo && personalInfo.links && personalInfo.links.length > 0 && (
                                                <tr>
                                                    <td style={{ padding: '5px', verticalAlign: 'text-top' }}>
                                                        <div style={{ fontSize: '18px', margin: 0, fontWeight: 'bold', borderBottom: '1px solid lightgray', paddingBottom: '8px' }}>LINKS </div>
                                                        <ul style={{ margin: '10px 0 0px 0px', padding: 0, fontSize: '14px', listStyleType: 'none' }}>
                                                            {personalInfo.links.map((link, index) => (
                                                                <li key={index} style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <div style={{ color: 'gray', marginRight: '8px', fontSize: '18px' }}>&#x2022;</div>
                                                                    <div className='flex'>
                                                                        <span className='mr-2'>{link.title}</span>
                                                                        [<a href={link.url} target="_blank" rel="noopener noreferrer">{link.url}</a>]
                                                                    </div>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </td>
                                <td style={{ width: '60%', verticalAlign: 'top', paddingLeft: '15px' }}>
                                    <table style={{ width: '100%' }}>
                                        <tbody>
                                            {experience && (
                                                <tr>
                                                    <td style={{ padding: '5px' }}>
                                                        <div style={{ fontSize: '18px', margin: 0, fontWeight: 'bold', borderBottom: '1px solid lightgray', paddingBottom: '8px' }}>WORK EXPERIENCE </div>
                                                        {/* {experience.slice().reverse().map((exp, index) => (
                                                            <div key={index} style={{ margin: '10px 0 2px 0', fontSize: '14px' }}>
                                                                <div style={{ margin: '0 0 5px 0', }}>
                                                                    <div style={{ fontWeight: 'bold', fontSize: '14px', textTransform: 'capitalize' }}>{exp.company}</div>
                                                                    <div style={{ fontStyle: 'italic', fontSize: '12px', textTransform: 'capitalize' }}>
                                                                        {exp.startMonth} {exp.startYear}
                                                                        {!exp.isCurrentCompany && ` - ${exp.endMonth} ${exp.endYear}`}
                                                                        {exp.isCurrentCompany && ' (Current)'}
                                                                    </div>
                                                                    <div style={{ fontWeight: 'bold', fontSize: '14px' }}>{exp.position}</div>
                                                                </div>
                                                                <div style={{ fontSize: '13px', color: '#242424' }}>{exp.description}</div>

                                                                {index !== experience.length - 1 && <div style={{ height: '5px', borderBottom: '1px solid lightgray', width: '20px', display: 'block', marginTop: '5px' }}></div>}
                                                            </div>
                                                        ))} */}

                                                        {experience.slice().reverse().map((exp, index) => (
                                                            <div key={index} style={{ margin: '10px 0 2px 0', fontSize: '14px' }}>
                                                                <div style={{ margin: '0 0 5px 0', }}>
                                                                    <div style={{ fontWeight: 'bold', fontSize: '14px', textTransform: 'capitalize' }}>{exp.company}</div>
                                                                    <div style={{ fontStyle: 'italic', fontSize: '12px', textTransform: 'capitalize' }}>
                                                                        {exp.startMonth} {exp.startYear}
                                                                        {!exp.isCurrentCompany && ` - ${exp.endMonth} ${exp.endYear}`}
                                                                        {exp.isCurrentCompany && ' (Current)'}
                                                                    </div>
                                                                    <div style={{ fontWeight: 'bold', fontSize: '14px' }}>{exp.position}</div>
                                                                </div>
                                                                <div style={{ fontSize: '13px', color: '#242424' }}>
                                                                    <div className="flex flex-col">
                                                                        {exp.description.split('.').map((sentence, index) => (
                                                                            <div key={index}>
                                                                                <table className='align-top'>
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td className='align-top'>&#x2022; {sentence.trim()}</td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>

                                                                {index !== experience.length - 1 && <div style={{ height: '5px', borderBottom: '1px solid lightgray', width: '20px', display: 'block', marginTop: '5px' }}></div>}
                                                            </div>
                                                        ))}

                                                    </td>
                                                </tr>
                                            )}
                                            {props.projectPosition && projects && projects.length > 0 && (
                                                <tr>
                                                    <td style={{ padding: '5px' }}>
                                                        <table style={{ width: '100%' }}>
                                                            <tbody>
                                                                <tr>
                                                                    <td style={{ fontSize: '18px', margin: 0, fontWeight: 'bold', borderBottom: '1px solid lightgray', paddingBottom: '8px', paddingLeft: '0px', paddingRight: '0px' }}>PROJECTS </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                                        <table style={{ width: '100%' }}>
                                                                            <tbody>
                                                                                {projects.map((project, index) => (
                                                                                    <tr key={index}>
                                                                                        <td style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                                                            <table style={{ width: '100%' }}>
                                                                                                <tbody>
                                                                                                    <tr>
                                                                                                        <td style={{ paddingLeft: '0px', paddingRight: '0px', paddingTop: '10px', paddingBottom: '5px' }}>
                                                                                                            <div style={{ fontSize: '14px', color: '#242424', fontWeight: 'bold' }}>{project.name}</div>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td style={{ paddingLeft: '0px', paddingRight: '0px', paddingTop: '0px', paddingBottom: '5px' }}>
                                                                                                            {/* <div style={{ fontSize: '13px', color: '#242424' }}>{project.description}</div> */}
                                                                                                            {project.description.split('.').map((sentence, index) => (
                                                                                                                <div key={index} className="flex items-start justify-start">
                                                                                                                    <table className='align-top'>
                                                                                                                        <tbody>
                                                                                                                            <tr>
                                                                                                                                <td className='align-top' style={{ paddingLeft: '0px', paddingRight: '0px', fontSize: '13px', color: '#242424' }}>&#x2022; {sentence.trim()}</td>
                                                                                                                            </tr>
                                                                                                                        </tbody>
                                                                                                                    </table>
                                                                                                                </div>
                                                                                                            ))}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </td>
                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            {!props.projectPosition && projects && projects.length > 0 && (
                                <tr>
                                    <td style={{ padding: '5px' }} colSpan={2}>
                                        <table style={{ width: '100%' }}>
                                            <tbody>
                                                <tr>
                                                    <td style={{ fontSize: '18px', margin: 0, fontWeight: 'bold', borderBottom: '1px solid lightgray', paddingBottom: '8px', paddingLeft: '0px', paddingRight: '0px' }}>PROJECTS </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                        <table style={{ width: '100%' }}>
                                                            <tbody>
                                                                {projects.map((project, index) => (
                                                                    <tr key={index}>
                                                                        <td style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                                            <table style={{ width: '100%' }}>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td style={{ paddingLeft: '0px', paddingRight: '0px', paddingTop: '10px', paddingBottom: '5px' }}>
                                                                                            <div style={{ fontSize: '14px', color: '#242424', fontWeight: 'bold' }}>{project.name}</div>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td style={{ paddingLeft: '0px', paddingRight: '0px', paddingTop: '0px', paddingBottom: '5px' }}>
                                                                                            {project.description.split('.').map((sentence, index) => (
                                                                                                <>
                                                                                                    {sentence.trim() != '' && (
                                                                                                        <div key={index} className="flex items-start justify-start">
                                                                                                            <table className='align-top'>
                                                                                                                <tbody>
                                                                                                                    <tr>
                                                                                                                        <td className='align-top' style={{ paddingLeft: '0px', paddingRight: '0px', fontSize: '13px', color: '#242424' }}>&#x2022; {sentence.trim()}</td>
                                                                                                                    </tr>
                                                                                                                </tbody>
                                                                                                            </table>
                                                                                                        </div>
                                                                                                    )}
                                                                                                </>
                                                                                            ))}
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
            {/* <div className='flex justify-center mb-10 mt-10 max-w-full'>
                <Button type="button" name={'Print Preview'} class="w-40" onClick={() => downloadPDF()} />
            </div> */}
        </>
    );
};

export default React.forwardRef(Professional); 
