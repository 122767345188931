import React, { useState, useEffect } from 'react';
import Input from "../../components/util/Input";
import Button from "../../components/util/Button";
import apiClient from '../../service/instance';
import { ResumeDataResponse } from '../../constants/types';
import FullLoader from '../../components/util/FullLoader';

interface CustomProps {
    submit: (tab?: number) => void;
}

interface Project {
    name: string;
    type: 'Major' | 'Minor';
    startMonth: string;
    startYear: string;
    endMonth: string;
    endYear: string;
    description: string;
}

const Projects = (props: CustomProps) => {
    const [saveBtnLoader, setSaveBtnLoader] = useState(false);
    const [loading, setLoading] = useState(false);
    const [projects, setProjects] = useState<Project[]>([{
        name: '',
        type: 'Major',
        startMonth: '',
        startYear: '',
        endMonth: '',
        endYear: '',
        description: ''
    }]);

    // useEffect(() => {
    //     const storedProjects = localStorage.getItem('projects');
    //     if (storedProjects) {
    //         setProjects(JSON.parse(storedProjects));
    //     }
    // }, []);

    useEffect(() => {
        getResumeData();
    }, []);

    const getResumeData = async () => {
        setLoading(true);
        try {
            const response: ResumeDataResponse = await apiClient.post('get_resume_data', {
                type: 'projects'
            });
            if (response.data.status) {
                const savedData = JSON.parse(response.data.resume_data.cgmu_data);
                setProjects(savedData);
            }
        } catch (error) {
        } finally {
            setLoading(false);
        }
    }

    const saveStep = async () => {
        setLoading(true);
        setSaveBtnLoader(true);
        try {
            setSaveBtnLoader(false);
            // Filter out empty projects
            const nonEmptyProjects = projects.filter(project =>
                project.name || project.startMonth || project.startYear || project.endMonth || project.endYear || project.description
            );
            // Generate JSON data and save to local storage
            const projectsData = JSON.stringify(nonEmptyProjects);
            // localStorage.setItem('projects', projectsData);

            // Make API request
            const response = await apiClient.post('save_resume', {
                type: 'projects',
                data: JSON.stringify(nonEmptyProjects)
            });

            if (!response.status) {
                throw new Error('Failed to save data');
            }

            props.submit(6);

        } catch (error) {
        } finally {
            setLoading(false);
        }
    }

    const handleProjectChange = (index: number, field: keyof Project, value: string) => {
        const newProjects = [...projects];
        newProjects[index][field] = value as any;
        setProjects(newProjects);
    };

    const addProject = () => {
        setProjects([...projects, { name: '', type: 'Major', startMonth: '', startYear: '', endMonth: '', endYear: '', description: '' }]);
    };

    const removeProject = (index: number) => {
        const newProjects = [...projects];
        newProjects.splice(index, 1);
        setProjects(newProjects);
    };

    const previousStep = () => {
        props.submit(3);
    }

    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 50 }, (_, i) => currentYear - i);

    return (
        <>
            <FullLoader show={loading} />
            <div className='block mt-9 max-w-[90%] m-auto'>
                <form>
                    <div className="flex flex-col gap-4">
                        {projects.map((project, index) => (
                            <div key={index} className="flex flex-col gap-4 p-4 border border-gray-200 rounded-md relative bg-[#edeff3]">
                                <div className="flex flex-col">
                                    <label htmlFor={`name-${index}`} className="block mb-2 text-sm text-gray-500">Project Name</label>
                                    <Input
                                        onChange={(e) => handleProjectChange(index, 'name', e.target.value)}
                                        placeholder="Enter project name"
                                        customClass=""
                                        type="text"
                                        label=""
                                        error={''}
                                        value={project.name}
                                        name={`name-${index}`}
                                    />
                                </div>
                                <div className="flex flex-col">
                                    <label htmlFor={`type-${index}`} className="block mb-2 text-sm text-gray-500">Project Type</label>
                                    <select
                                        onChange={(e) => handleProjectChange(index, 'type', e.target.value as 'Major' | 'Minor')}
                                        className="numo-outer-btn-200 hover:nm-flat-gray-200-lg py-2 bg-white rounded-full px-4 placeholder:text-xs  w-full  "
                                        value={project.type}
                                        name={`type-${index}`}
                                    >
                                        <option value="Major">Major</option>
                                        <option value="Minor">Minor</option>
                                    </select>
                                </div>
                                <div className="flex flex-col gap-4">
                                    <div className="flex flex-col flex-1">
                                        <label htmlFor={`startMonth-${index}`} className="block mb-2 text-sm text-gray-500">Start Date</label>
                                        <div className="flex gap-2">
                                            <select
                                                onChange={(e) => handleProjectChange(index, 'startMonth', e.target.value)}
                                                className="numo-outer-btn-200 hover:nm-flat-gray-200-lg py-2 bg-white rounded-full px-4 placeholder:text-xs w-full"
                                                value={project.startMonth}
                                                name={`startMonth-${index}`}
                                            >
                                                <option value="">Month</option>
                                                {months.map((month) => (
                                                    <option key={month} value={month}>{month}</option>
                                                ))}
                                            </select>
                                            <select
                                                onChange={(e) => handleProjectChange(index, 'startYear', e.target.value)}
                                                className="numo-outer-btn-200 hover:nm-flat-gray-200-lg py-2 bg-white rounded-full px-4 placeholder:text-xs w-full"
                                                value={project.startYear}
                                                name={`startYear-${index}`}
                                            >
                                                <option value="">Year</option>
                                                {years.map((year) => (
                                                    <option key={year} value={year.toString()}>{year}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="flex flex-col flex-1">
                                        <label htmlFor={`endMonth-${index}`} className="block mb-2 text-sm text-gray-500">End Date</label>
                                        <div className="flex gap-2">
                                            <select
                                                onChange={(e) => handleProjectChange(index, 'endMonth', e.target.value)}
                                                className="numo-outer-btn-200 hover:nm-flat-gray-200-lg py-2 bg-white rounded-full px-4 placeholder:text-xs w-full"
                                                value={project.endMonth}
                                                name={`endMonth-${index}`}
                                            >
                                                <option value="">Month</option>
                                                {months.map((month) => (
                                                    <option key={month} value={month}>{month}</option>
                                                ))}
                                            </select>
                                            <select
                                                onChange={(e) => handleProjectChange(index, 'endYear', e.target.value)}
                                                className="numo-outer-btn-200 hover:nm-flat-gray-200-lg py-2 bg-white rounded-full px-4 placeholder:text-xs w-full"
                                                value={project.endYear}
                                                name={`endYear-${index}`}
                                            >
                                                <option value="">Year</option>
                                                {years.map((year) => (
                                                    <option key={year} value={year.toString()}>{year}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col">
                                    <label htmlFor={`description-${index}`} className="block mb-2 text-sm text-gray-500">Description</label>
                                    <Input
                                        onChange={(e) => handleProjectChange(index, 'description', e.target.value)}
                                        placeholder="Describe your project"
                                        customClass=""
                                        type="textarea"
                                        label=""
                                        error={''}
                                        value={project.description}
                                        name={`description-${index}`}
                                    />
                                </div>
                                {index > 0 && (
                                    <button
                                        type="button"
                                        onClick={() => removeProject(index)}
                                        className='absolute top-2 right-2 opacity-50'
                                    >
                                        <svg width={20} height={20} viewBox="0 0 512 512"><path d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="32" /><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M320 320L192 192M192 320l128-128" /></svg>
                                    </button>
                                )}
                            </div>
                        ))}
                        <button
                            type="button"
                            onClick={addProject}
                            className="text-blue-500 hover:text-blue-700 mt-2 flex flex-row items-center gap-2 justify-center font-normal text-sm"
                        >
                            Add Project
                            <svg width={20} height={20} viewBox="0 0 512 512"><path d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="32" /><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M256 176v160M336 256H176" /></svg>
                        </button>
                        <div className="flex justify-center pt-9 items-center gap-4">
                            <Button type="button" name={'Previous'} class="w-32" loader={saveBtnLoader} onClick={() => previousStep()} />
                            <Button type="button" name={'Next'} class="w-32" loader={saveBtnLoader} onClick={() => saveStep()} />
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export default Projects;
