import React, { useEffect, useState } from 'react';
import ProgressBar from '../../components/Progress';
import PersonalInfo from './PersonalInfo';
import { tabs } from '../../constants/resumeTabs';
import Experiance from './Experiance';
import Skills from './Skills';
import Education from './Education';
import Projects from './Projects';
import Summary from './Summary';
import Header from './Header';
import TopHeader from '../../components/topHeader';

const CreateResume = () => {
    const [activeTab, setActiveTab] = useState(0);

    const handleSubmit = (tab?: number) => {
        if (tab !== undefined) {
            const nextTabIndex = tabs.findIndex(t => t.id === tab + 1);
            if (nextTabIndex !== -1) {
                setActiveTab(nextTabIndex);
            } else {
                // If there's no next tab, we've reached the end
                setActiveTab(tabs.length - 1);
            }
        } else {
            setActiveTab(0);
        }
        // Scroll to the top of the screen after every submit
        window.scrollTo(0, 0);
    }

    const changeTab = (tab: number) => {
        setActiveTab(tab);
    }



    return (
        <>
            <div className="h-full w-full p-5">
                <TopHeader />
                <div className="container mx-auto max-w-96 mt-10">
                    <Header />
                    <ProgressBar activeTab={activeTab} changeTab={changeTab} />
                    {tabs.map((tab, index) => {
                        switch (tab.name) {
                            case 'Personal Info':
                                return activeTab === index && <PersonalInfo key={tab.id} submit={handleSubmit} />;
                            case 'Experience':
                                return activeTab === index && <Experiance key={tab.id} submit={handleSubmit} />;
                            case 'Skills':
                                return activeTab === index && <Skills key={tab.id} submit={handleSubmit} />;
                            case 'Education':
                                return activeTab === index && <Education key={tab.id} submit={handleSubmit} />;
                            case 'Projects':
                                return activeTab === index && <Projects key={tab.id} submit={handleSubmit} />;
                            case 'Summary':
                                return activeTab === index && <Summary key={tab.id} submit={handleSubmit} />;
                            // Add cases for other tabs as needed
                            default:
                                return null;
                        }
                    })}
                </div>
            </div>
        </>
    );
};

export default CreateResume;
