import React from 'react';
import AuthScreen from './pages/auth/Auth';
import FullLoader from './components/util/FullLoader';

function App() {
  return (
    <div className="App">
      <FullLoader show={false} />
    </div>
  );
}

export default App;
