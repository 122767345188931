import React, { forwardRef, useRef } from 'react';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
    onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    placeholder?: string;
    customClass?: string;
    label?: string;
    error?: string;
    defaultValue?: string;
    disabled?: boolean;
    errorText?: string
    ref?: React.Ref<HTMLInputElement | HTMLTextAreaElement>;
    name?: string;
    value?: string;
    type?: 'text' | 'textarea' | string;
    parentClass?: string;
}

const Input = forwardRef<HTMLInputElement | HTMLTextAreaElement, InputProps>((props, ref) => {
    const textareaRef = useRef<HTMLTextAreaElement | null>(null);

    React.useEffect(() => {
        if (props.type === 'textarea' && textareaRef.current) {
            adjustTextareaHeight(textareaRef.current);
        }
    }, [props.value, props.type]);

    const adjustTextareaHeight = (element: HTMLTextAreaElement) => {
        element.style.height = 'auto';
        element.style.height = `${element.scrollHeight}px`;
    };

    const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        adjustTextareaHeight(e.target);
        props.onChange(e);
    };

    return (
        <div className={`input-wrapper ${props?.parentClass}`}>
            {props?.label && <label className="block mb-1 text-sm font-medium">{props?.label}</label>}
            {props.type === 'textarea' ? (
                <textarea
                    ref={(el) => {
                        if (typeof ref === 'function') {
                            ref(el);
                        } else if (ref) {
                            (ref as React.MutableRefObject<HTMLTextAreaElement | null>).current = el;
                        }
                        textareaRef.current = el;
                    }}
                    className={`py-2 bg-white rounded-2xl px-4 placeholder:text-xs numo-inset-md w-full  ${props?.customClass} ${props.disabled ? '!bg-[#e5e7eb]' : ''} ${props?.error ? 'border-red-500 border-[1px] outline-none' : ''}`}
                    placeholder={props?.placeholder}
                    onChange={handleTextareaChange}
                    defaultValue={props?.defaultValue}
                    disabled={props?.disabled}
                    name={props?.name}
                    value={props?.value}
                    style={{ minHeight: '80px', resize: 'none', overflow: 'hidden' }}
                />
            ) : (
                <input
                    ref={ref as React.Ref<HTMLInputElement>}
                    type={props?.type}
                    className={`py-2 bg-white rounded-full px-4 placeholder:text-xs numo-inset-md w-full  ${props?.customClass} ${props.disabled ? '!bg-[#e5e7eb]' : ''} ${props?.error ? 'border-red-500 border-[1px] outline-none' : ''}`}
                    placeholder={props?.placeholder}
                    onChange={props?.onChange}
                    defaultValue={props?.defaultValue}
                    disabled={props?.disabled}
                    name={props?.name}
                    value={props?.value}
                />
            )}

            {props?.errorText && <p className="text-red-500 text-xs mt-1">{props?.errorText}</p>}
        </div>
    );
});

export default Input;
