import { useNavigate } from "react-router-dom";
const BottomTab = () => {
    const navigate = useNavigate();
    return (
        <div className="w-[80%] max-w-[300px] rounded-full h-10 numo-outer-btn-200 hover:nm-flat-gray-200-lg leading-5 px-4 py-1 inline-flex items-center justify-center  font-normal tracking-widest text-sm transition duration-200 ease-in-out  fixed bottom-6 left-1/2 transform -translate-x-1/2  ">

            <a className="text-black w-[50%] text-center border-r-[1px] border-gray-300 cursor-pointer" onClick={() => navigate('/create-cv')}>Create CV</a>
            <a className="text-black w-[50%] text-center border-l-[1px] border-gray-300 cursor-pointer" onClick={() => navigate('/view-cv')}>View CV</a>

        </div>
    )
}

export default BottomTab;