import React, { useRef, useEffect, useState, useImperativeHandle } from 'react';
import { jsPDF } from "jspdf";
import Button from '../../../components/util/Button';
import apiClient from '../../../service/instance';
import { ResumeDataResponse } from '../../../constants/types';
import FullLoader from '../../../components/util/FullLoader';

interface PersonalInfo {
    fullName: string;
    title: string;
    phoneNumbers: PhoneNumber[];
    email: string;
    website: string;
    profile: string;
    languages: Language[];
    currentPosition: string;
    dob: { day: string; month: string; year: string };
    address: string;
    country: string;
    links: Links[];
}

interface Links {
    title: string;
    url: string;
}

interface PhoneNumber {
    number: string;
    countryCode: string;
}

interface Skill {
    name: string;
}

interface Education {
    institution: string;
    degree: string;
    date: string;
    startMonth: string;
    startYear: string;
    endMonth: string;
    endYear: string;
    fieldOfStudy: string;
}

interface Project {
    name: string;
    role: string;
    date: string;
    description: string;
}

interface Experience {
    company: string;
    position: string;
    date: string;
    description: string;
    startMonth: string;
    startYear: string;
    endMonth: string;
    endYear: string;
    isCurrentCompany: boolean;
}

interface Language {
    language: string;
    level: string;
}

interface Props {
    downloadPdf?: string;
    design?: string;
    selectedFont?: string;
}

const Elegant = (props: Props, ref: any) => {
    const contentRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [personalInfo, setPersonalInfo] = useState<PersonalInfo | null>(null);
    const [skills, setSkills] = useState<Skill[]>([]);
    const [education, setEducation] = useState<Education[]>([]);
    const [projects, setProjects] = useState<Project[]>([]);
    const [experience, setExperience] = useState<Experience[]>([]);
    const [summary, setSummary] = useState<string>('');

    const [resumeData, setResumeData] = useState<any>(null);
    const [design, setDesign] = useState<string>('professional');
    const [selectedFont, setSelectedFont] = useState<string>('Arial, sans-serif');

    useImperativeHandle(ref, () => ({
        downloadFile: downloadPDF
    }));

    useEffect(() => {
        getResumeData();
    }, []);

    useEffect(() => {
        setSelectedFont(props.selectedFont || 'Arial, sans-serif');
    }, [props.selectedFont]);




    const getResumeData = async () => {
        setLoading(true);
        try {
            const response: any = await apiClient.get('get_all_resume_data');

            if (response.data.status) {
                const resumeDataArray = response.data.resume_data;
                return setResumeDataToState(resumeDataArray);
            }
        } catch (error) {
            return false;
        }
    };

    const setResumeDataToState = (resumeDataArray: any) => {
        const personalInfoData = resumeDataArray.find((item: any) => item.cgmu_type === 'personalinfo');
        const summaryData = resumeDataArray.find((item: any) => item.cgmu_type === 'summary');
        const skillsData = resumeDataArray.find((item: any) => item.cgmu_type === 'skills');
        const educationData = resumeDataArray.find((item: any) => item.cgmu_type === 'education');
        const experienceData = resumeDataArray.find((item: any) => item.cgmu_type === 'experiance');
        const projectsData = resumeDataArray.find((item: any) => item.cgmu_type === 'projects');

        if (personalInfoData) {
            setPersonalInfo(JSON.parse(personalInfoData.cgmu_data));
        }
        if (summaryData) {
            setSummary(JSON.parse(summaryData.cgmu_data));
        }
        if (skillsData) {
            setSkills(JSON.parse(skillsData.cgmu_data));
        }
        if (educationData) {
            setEducation(JSON.parse(educationData.cgmu_data));
        }
        if (experienceData) {
            setExperience(JSON.parse(experienceData.cgmu_data));
        }
        if (projectsData) {
            setProjects(JSON.parse(projectsData.cgmu_data));
        }

        setResumeData(resumeDataArray);
        if (resumeDataArray.length > 0) {
            setLoading(false);
            return true;
        }
        return false;
    }

    const downloadPDF = async () => {
        const resumeData = await getResumeData();
        if (!resumeData) {
            return false;
        }
        else {
            const doc = new jsPDF('p', 'px', 'a4');
            const content = contentRef.current;
            if (content) {
                // Optional - Add styling to the PDF
                doc.setFontSize(6);

                // Add HTML content to PDF
                doc.html(content, {
                    callback: function (doc) {
                        doc.save(`${personalInfo?.fullName}-Resume.pdf`);
                    },
                    // x: 10,
                    // y: 10,
                    margin: 10,
                    width: 2480,
                    autoPaging: 'text',
                    html2canvas: {
                        scale: 0.5, // Adjust scale to 1 for normal size (can be 2 for high quality)
                    },
                });
            }
        }
    }

    if (!resumeData) {
        return (<FullLoader show={loading} />);
    }

    return (
        <>
            <FullLoader show={loading} />
            <div style={{ width: '884px', minHeight: '1280px', backgroundColor: 'white', border: '1px solid white', margin: '20px auto', padding: '20px', }}>
                <div id="content-to-print" ref={contentRef} style={{
                    scale: 1,
                    width: '834px',
                    margin: '0 auto',
                    maxWidth: '100%'
                }}>
                    <table style={{
                        width: '844px',
                        fontFamily: selectedFont,
                        fontSize: '16px',
                        lineHeight: 1.6,
                        letterSpacing: '0.01em',
                        // height: '1222px',
                    }}>
                        <tbody>

                            <tr>
                                <td style={{ width: '100%' }}>
                                    <table style={{ width: '100%' }}>
                                        <tbody>
                                            {personalInfo && personalInfo.fullName && (
                                                <>
                                                    <tr>
                                                        <td style={{ width: '100%', fontSize: '28px', fontWeight: '300', margin: 0, lineHeight: "1.2" }}>
                                                            {personalInfo.fullName}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: '100%', fontSize: '16px', fontWeight: 'bold', margin: 0, lineHeight: "1.2" }}>
                                                            {personalInfo.currentPosition}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: '100%', fontSize: '16px', fontWeight: 'bold', margin: 0, lineHeight: "1.2" }}>
                                                            {personalInfo.title}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: '100%', fontSize: '14px', margin: 0, lineHeight: "1.2" }}>
                                                            {personalInfo.phoneNumbers && personalInfo.phoneNumbers.length > 0 && personalInfo.phoneNumbers.map((phone, index) => (
                                                                <React.Fragment key={index}>
                                                                    {phone.countryCode && phone.number && (
                                                                        <>
                                                                            {'+' + phone.countryCode} {phone.number}
                                                                            {index < personalInfo.phoneNumbers.length - 1 && ' | '}
                                                                        </>
                                                                    )}
                                                                </React.Fragment>
                                                            ))}

                                                            {personalInfo.email && ' | ' + personalInfo.email}
                                                            {personalInfo.website && ' | ' + personalInfo.website}
                                                            {personalInfo.address && ' | ' + personalInfo.address}
                                                            {personalInfo.country && ' | ' + personalInfo.country}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ padding: '10px 0px 10px 0px' }}>
                                                            <table style={{ width: '100%' }}>
                                                                <tbody>
                                                                    <tr>
                                                                        <td style={{ backgroundColor: '#f5f5f5', padding: '8px 10px', fontSize: '14px' }}>
                                                                            {summary}
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>

                                                </>
                                            )}

                                            {skills && skills.length > 0 && (
                                                <tr>
                                                    <td style={{ paddingBottom: '10px' }}>
                                                        <table style={{ width: '100%' }}>
                                                            <tbody>
                                                                <tr>
                                                                    <td style={{ fontSize: '16px', margin: 0, fontWeight: 'bold', borderBottom: '1px solid lightgray', paddingBottom: '0px' }}>SKILLS </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ fontSize: '14px', paddingTop: '5px', width: '100%' }}>
                                                                        {skills.map((skill, index) => (
                                                                            skill.name + (index === skills.length - 1 ? '' : ' | ')
                                                                        ))}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>

                                            )}

                                            {education && education.length > 0 && (
                                                <tr>
                                                    <td style={{ paddingBottom: '10px' }}>
                                                        <table style={{ width: '100%' }}>
                                                            <tbody>
                                                                <tr>
                                                                    <td style={{ fontSize: '16px', margin: 0, fontWeight: 'bold', borderBottom: '1px solid lightgray', paddingBottom: '0px', }}>EDUCATION </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ fontSize: '16px', width: '100%', paddingLeft: '0px', paddingRight: '0px' }}>
                                                                        {education.map((edu, index) => (
                                                                            <table key={index} style={{ width: '100%', paddingLeft: '0px', paddingRight: '0px' }}>
                                                                                <tbody style={{ lineHeight: '1.2' }}>
                                                                                    <tr key={index}>
                                                                                        <td style={{ width: '100%', paddingTop: '8px', paddingLeft: '0px', paddingRight: '0px' }}>
                                                                                            <table style={{ width: '100%' }}>
                                                                                                <tbody style={{ lineHeight: '1.2' }}>
                                                                                                    <tr>
                                                                                                        <td style={{ width: '100%', fontWeight: 'bold', fontSize: '16px', paddingLeft: '0px', paddingRight: '0px' }}>
                                                                                                            {edu.degree}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    {edu.institution && (
                                                                                                        <tr>
                                                                                                            <td style={{ width: '100%', fontSize: '14px', paddingLeft: '0px', paddingRight: '0px' }}>
                                                                                                                {edu.institution} {edu.startYear && edu.endYear ? ' | ' : ''} {edu.startMonth} {edu.startYear}
                                                                                                                {edu.endMonth && edu.endYear && ` - ${edu.endMonth} ${edu.endYear}`}
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    )}

                                                                                                    {edu.fieldOfStudy && (
                                                                                                        <tr>
                                                                                                            <td style={{ width: '100%', fontSize: '14px', paddingLeft: '0px', paddingRight: '0px' }}>
                                                                                                                {edu.fieldOfStudy}
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    )}

                                                                                                </tbody>
                                                                                            </table>
                                                                                        </td>

                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        ))}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            )}

                                            {personalInfo && personalInfo.languages && personalInfo.languages.length > 0 && (
                                                <tr>
                                                    <td style={{ paddingBottom: '10px' }}>
                                                        <table style={{ width: '100%' }}>
                                                            <tbody>
                                                                <tr>
                                                                    <td style={{ fontSize: '16px', margin: 0, fontWeight: 'bold', borderBottom: '1px solid lightgray', paddingBottom: '0px', }}>LANGUAGES </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ fontSize: '16px', paddingTop: '5px', width: '100%' }}>
                                                                        {personalInfo.languages.map((lang, index) => (
                                                                            <span key={index}>
                                                                                {lang.language} - <span style={{ fontStyle: 'normal', fontSize: '12px' }}>({lang.level})</span>
                                                                                {index === personalInfo.languages.length - 1 ? '' : ' | '}
                                                                            </span>
                                                                        ))}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            )}

                                            {personalInfo && personalInfo.links && personalInfo.links.length > 0 && (
                                                <tr>
                                                    <td style={{ paddingBottom: '10px' }}>
                                                        <table style={{ width: '100%' }}>
                                                            <tbody>
                                                                <tr>
                                                                    <td style={{ fontSize: '16px', margin: 0, fontWeight: 'bold', borderBottom: '1px solid lightgray', paddingBottom: '0px', }}>LINKS </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ fontSize: '16px', paddingTop: '5px', width: '100%' }}>
                                                                        <table style={{ width: '100%' }}>
                                                                            <tbody>
                                                                                {personalInfo.links.map((link, index) => (
                                                                                    <tr key={index}>
                                                                                        <td style={{ width: '100%', fontSize: '14px', paddingLeft: '0px', paddingRight: '0px' }}>
                                                                                            {link.title} {' - '}
                                                                                            [<a style={{ fontStyle: 'normal', fontSize: '12px' }} href={link.url} target="_blank" rel="noopener noreferrer">{link.url}</a>]
                                                                                        </td>
                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            )}

                                            {experience && experience.length > 0 && (
                                                <tr>
                                                    <td style={{ paddingBottom: '10px' }}>
                                                        <table style={{ width: '100%' }}>
                                                            <tbody>
                                                                <tr>
                                                                    <td style={{ fontSize: '16px', margin: 0, fontWeight: 'bold', borderBottom: '1px solid lightgray', paddingBottom: '0px', }}>WORK EXPERIENCE </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ fontSize: '16px', paddingTop: '5px', width: '100%' }}>
                                                                        {experience.map((exp, index) => (
                                                                            <table key={index} style={{ width: '100%', paddingLeft: '0px', paddingRight: '0px' }}>
                                                                                <tbody style={{ lineHeight: '1.2' }}>
                                                                                    <tr>
                                                                                        <td style={{ width: '100%', paddingBottom: '8px', paddingLeft: '0px', paddingRight: '0px' }}>
                                                                                            <table style={{ width: '100%' }}>
                                                                                                <tbody>
                                                                                                    <tr>
                                                                                                        <td style={{ width: '100%', paddingLeft: '0px', paddingRight: '0px' }}>
                                                                                                            <table style={{ width: '100%' }}>
                                                                                                                <tbody>
                                                                                                                    <tr>
                                                                                                                        <td style={{ width: '70%', fontWeight: 'bold', fontSize: '16px', paddingLeft: '0px', paddingRight: '0px', textAlign: 'left' }}>
                                                                                                                            {exp.company}
                                                                                                                        </td>
                                                                                                                        <td style={{ width: '30%', fontSize: '12px', paddingLeft: '0px', paddingRight: '0px', textAlign: 'right', fontStyle: 'italic' }}>
                                                                                                                            {exp.startMonth.substring(0, 3)} {exp.startYear}
                                                                                                                            {!exp.isCurrentCompany && ` - ${exp.endMonth.substring(0, 3)} ${exp.endYear}`}
                                                                                                                            {exp.isCurrentCompany && ' (Current)'}
                                                                                                                        </td>
                                                                                                                    </tr>
                                                                                                                </tbody>
                                                                                                            </table>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td style={{ width: '70%', paddingLeft: '0px', paddingRight: '0px' }}>
                                                                                                            <table style={{ width: '100%' }}>
                                                                                                                <tbody>
                                                                                                                    <tr>
                                                                                                                        <td style={{ width: '70%', fontSize: '14px', fontWeight: '200', paddingLeft: '0px', paddingRight: '0px', paddingBottom: '5px' }}>
                                                                                                                            {exp.position}
                                                                                                                        </td>
                                                                                                                    </tr>
                                                                                                                    <tr>
                                                                                                                        <td style={{ width: '70%', fontSize: '13px', paddingLeft: '0px', paddingRight: '0px', paddingBottom: '2px' }}>
                                                                                                                            {exp.description.split('.').map((sentence, index) => (
                                                                                                                                <table key={index} style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                                                                                                    <tbody>
                                                                                                                                        <tr>
                                                                                                                                            <td style={{ paddingLeft: '0px', paddingRight: '0px', verticalAlign: 'top', paddingBottom: '4px' }}>&#x2022; {sentence.trim()}</td>
                                                                                                                                        </tr>
                                                                                                                                    </tbody>
                                                                                                                                </table>
                                                                                                                            ))}
                                                                                                                        </td>
                                                                                                                        <td style={{ width: '30%', paddingLeft: '0px', paddingRight: '0px', textAlign: 'right', fontStyle: 'italic' }}></td>
                                                                                                                    </tr>
                                                                                                                </tbody>
                                                                                                            </table>
                                                                                                        </td>
                                                                                                    </tr>

                                                                                                </tbody>
                                                                                            </table>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        ))}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            )}

                                            {projects && projects.length > 0 && (
                                                <tr>
                                                    <td style={{ paddingBottom: '10px', paddingLeft: '0px', paddingRight: '0px' }}>
                                                        <table style={{ width: '100%' }}>
                                                            <tbody>
                                                                <tr>
                                                                    <td style={{ fontSize: '16px', margin: 0, fontWeight: 'bold', borderBottom: '1px solid lightgray', paddingBottom: '0px', paddingLeft: '0px', paddingRight: '0px' }}>PROJECTS </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ fontSize: '16px', paddingTop: '5px', width: '100%', paddingLeft: '0px', paddingRight: '0px' }}>
                                                                        {projects.map((project, index) => (
                                                                            <table key={index} style={{ width: '100%', paddingLeft: '0px', paddingRight: '0px' }}>
                                                                                <tbody style={{ lineHeight: '1.2' }}>
                                                                                    <tr>
                                                                                        <td style={{ width: '100%', paddingBottom: '8px', paddingLeft: '0px', paddingRight: '0px' }}>
                                                                                            <table style={{ width: '100%' }}>
                                                                                                <tbody style={{ lineHeight: '1.2' }}>
                                                                                                    <tr>
                                                                                                        <td style={{ width: '70%', fontWeight: 'bold', fontSize: '16px', paddingLeft: '0px', paddingRight: '0px', textAlign: 'left', paddingBottom: '5px' }}>
                                                                                                            {project.name}
                                                                                                        </td>
                                                                                                    </tr>

                                                                                                    <tr>
                                                                                                        <td style={{ width: '70%', fontSize: '13px', paddingLeft: '0px', paddingRight: '0px' }}>
                                                                                                            {project.description.split('.').map((sentence, index) => (

                                                                                                                <table key={index} style={{ width: '100%' }}>
                                                                                                                    <tbody>
                                                                                                                        <tr>
                                                                                                                            <td style={{ paddingLeft: '0px', paddingRight: '0px', verticalAlign: 'top', paddingBottom: '5px' }}>&#x2022; {sentence.trim()}</td>
                                                                                                                        </tr>
                                                                                                                    </tbody>
                                                                                                                </table>

                                                                                                            ))}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        ))}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            )}

                                        </tbody>
                                    </table>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div >
        </>
    );
};

export default React.forwardRef(Elegant); 
