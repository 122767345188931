import React, { useRef, useEffect, useState } from 'react';
import Header from './Header';
import Button from '../../components/util/Button';
import { useNavigate } from 'react-router-dom';
import apiClient from '../../service/instance';
import { BillingDetailsResponse, ResumeData, ResumeDataResponse, User } from '../../constants/types';
import TopHeader from '../../components/topHeader';
import Elegant from './designs/Elegant';
import Professional from './designs/Professional';
import FullLoader from '../../components/util/FullLoader';

declare global {
    interface Window {
        Razorpay: any;
    }
}

const ViewCv = () => {
    const [scale, setScale] = useState(1);
    const containerRef = useRef<HTMLDivElement>(null);
    const [downloadPdf, setDownloadPdf] = useState<any>();
    const [containerHeight, setContainerHeight] = useState(0);
    const [showPayment, setShowPayment] = useState(false);
    const [purchaseCompleted, setPurchaseCompleted] = useState(true);
    const [resumeData, setResumeData] = useState<ResumeData | null>(null);
    const [billingDetails, setBillingDetails] = useState<BillingDetailsResponse | null>(null);
    const [paymentID, setPaymentID] = useState<string | undefined>(undefined);
    const [user, setUser] = useState<User | null>(null);
    const [design, setDesignState] = useState<string>('professional');
    const [selectedFont, setSelectedFontState] = useState<string>('Arial, sans-serif');
    const [projectPosition, setProjectPositionState] = useState<boolean>(true);
    const [loader, setLoader] = useState(false);

    const childRef = useRef();
    const fontArray = [
        { id: 1, label: "Arial", value: "Arial, sans-serif" },
        { id: 2, label: "Playfair", value: "Playfair Display, serif" },
        // { id: 3, label: "Raleway", value: "Raleway, sans-serif" }
    ];
    const [downloadTrigger, setDownloadTrigger] = useState(0);

    const resumeDesignTypes = [
        { label: "Professional", id: "professional", value: "Professional" },
        { label: "Elegant", id: "elegant", value: "Elegant" },
        // { label: "Classic", id: "classic", value: "Classic" },
        // { label: "Modern", id: "modern", value: "Modern" },
        // { label: "Minimalist", id: "minimalist", value: "Minimalist" },
        // { label: "Creative", id: "creative", value: "Creative" },
        // { label: "Formal", id: "formal", value: "Formal" },
        // { label: "Sophisticated", id: "sophisticated", value: "Sophisticated" },
        // { label: "Dynamic", id: "dynamic", value: "Dynamic" },
        // { label: "Executive", id: "executive", value: "Executive" },
        // { label: "Stylish", id: "stylish", value: "Stylish" },
        // { label: "Contemporary", id: "contemporary", value: "Contemporary" },
        // { label: "Chic", id: "chic", value: "Chic" },
        // { label: "Premium", id: "premium", value: "Premium" },
        // { label: "Versatile", id: "versatile", value: "Versatile" },
        // { label: "Refined", id: "refined", value: "Refined" },
        // { label: "Bold", id: "bold", value: "Bold" },
        // { label: "Polished", id: "polished", value: "Polished" },
        // { label: "Innovative", id: "innovative", value: "Innovative" },
        // { label: "Streamlined", id: "streamlined", value: "Streamlined" }
    ];

    useEffect(() => {
        getResumeData();
        const handleResize = () => {
            const screenWidth = window.innerWidth;
            let newScale = 1;
            if (screenWidth <= 1000) {
                newScale = screenWidth / 1000;
            }
            else {
                newScale = 1;
            }
            setScale(newScale);

            // Update container height after scaling
            // if (containerRef.current) {
            //     const scaledHeight = containerRef.current.scrollHeight;
            //     setContainerHeight(scaledHeight);
            // }
        };

        // Initial call
        handleResize();

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const navigate = useNavigate();


    const getResumeData = async () => {
        try {
            const response: ResumeDataResponse = await apiClient.post('get_resume_data', {
                type: 'personalinfo'
            });

            if (response.data.status) {
                const savedData = JSON.parse(response.data.resume_data.cgmu_data);
                setResumeData(savedData);
            }
        } catch (error) {
            // Handle error here (e.g., show error message to user)
        }
    };

    const setDownloadCountApi = async () => {
        setLoader(true);
        try {
            const response: any = await apiClient.post('post_download_count', {
                type: design,
                font: selectedFont
            });
            if (response.data.status) {
                setLoader(false);
                return true;
            }
            return false;
        } catch (error) {
            setLoader(false);
            return false;
        }
    }



    const handleDownloadInChild = () => {
        
        setDownloadCountApi().then((res) => {
            if (res) {
                setDownloadTrigger(prev => {
                    const newTrigger = prev + 1;
                    setTimeout(() => {
                        if (childRef.current) {
                            (childRef.current as any).downloadFile();
                        }
                    }, 1000);
                    return newTrigger;
                });
            }
        });
    };

    useEffect(() => {
        const loadRazorpay = () => {
            return new Promise((resolve) => {
                const script = document.createElement('script');
                script.src = 'https://checkout.razorpay.com/v1/checkout.js';
                script.onload = () => {
                    resolve(true);
                };
                script.onerror = () => {
                    resolve(false);
                };
                document.body.appendChild(script);
            });
        };

        loadRazorpay();
        getUser();

    }, []);

    const getBillingDetails = async () => {
        try {
            const response: any = await apiClient.get('get_bpinr');
            if (response.data.status) {
                setBillingDetails(response.data);
                return response.data.billing_details;
            }
        } catch (error) {
        }
        return null;
    }

    const handlePayment = async () => {
        setShowPayment(true);
        if (typeof window.Razorpay === 'undefined') {
            alert('Razorpay SDK failed to load. Please check your internet connection.');
            return;
        }
        const options = {
            key: 'rzp_live_H4owynDQx2W8Xf',
            amount: billingDetails?.bpinr, // Amount in paise (e.g., 100000 for ₹1000)
            currency: 'INR',
            name: 'WioCV',
            description: 'Resume Download',
            handler: function (response: any) {
                // Handle successful payment
                setPaymentID(response.razorpay_payment_id);
                setPurchaseCompleted(true);
                handleDownloadInChild();

            },
            prefill: {
                name: resumeData?.personalinfo?.fullName || user?.user_info?.cgmu_email,
                email: resumeData?.personalinfo?.email || user?.user_info?.cgmu_email,
                contact: resumeData?.personalinfo?.phoneNumbers[0]?.number || user?.user_info?.cgmu_phone
            },
            theme: {
                color: '#424242'
            }
        };

        const rzp = new window.Razorpay(options);
        rzp.open();
    }

    useEffect(() => {
        if (paymentID) {
            savePaymentInfo(paymentID);
        }
    }, [paymentID]);

    const savePaymentInfo = async (paymentID: string) => {
        try {
            const response: any = await apiClient.post('post_payment_info', {
                payment_id: paymentID,
                billing_details: billingDetails?.bpinr,
                email: resumeData?.personalinfo?.email || user?.user_info?.cgmu_email,
                name: resumeData?.personalinfo?.fullName || user?.user_info?.cgmu_email
            });
            if (response.data.status) {
                setPurchaseCompleted(true);
            }
        } catch (error) {
        }
    }

    useEffect(() => {
        if (billingDetails) {
            handlePayment();
        }
    }, [billingDetails]);

    // const handleDownloadClick = async () => {
    //     if (purchaseCompleted) {
    //         handleDownloadInChild();
    //     }
    //     // else {
    //     //     const billingDetails = await getBillingDetails();

    //     //     if (billingDetails?.status) {
    //     //         setBillingDetails(billingDetails);
    //     //     } else {
    //     //     }
    //     // }
    // };

    const getUser = async () => {
        const response: any = await apiClient.get('get_user_info_by_token');
        if (response.data.status) {
            setUser(response.data);
        }
    }

    const setDesign = (design: string) => {
        setDesignState(design);
    }

    const setSelectedFont = (font: string) => {
        setSelectedFontState(font);
    }

    const handlePositionChange = (position: string) => {
        setProjectPositionState(position === 'false'); 
    }




    return (
        <>
            <TopHeader />
            <FullLoader show={loader}/>
            <div className='mt-8'>
                <Header />
            </div>
            <div className='resumeBoxParent  max-md:overflow-y-scroll max-md:overflow-x-hidden'>
                <div
                    className='resumeBoxContainer'
                    ref={containerRef}
                    style={{
                        transform: `scale(${scale})`,
                        transformOrigin: 'top left',
                        width: `${100 / scale}%`,
                        height: `${scale !== 1 ? (180 * scale * 8) : ''}px`,
                    }}
                >
                    {design === 'professional' && <Professional key={downloadTrigger} ref={childRef} downloadPdf={paymentID} design={design} selectedFont={selectedFont} projectPosition={projectPosition} />}
                    {design === 'elegant' && <Elegant key={downloadTrigger} ref={childRef} downloadPdf={paymentID} design={design} selectedFont={selectedFont} />}
                </div>
            </div>
            <div className='notes text-center text-[12px] text-gray-500 mt-4 max-w-[400px] w-full mx-auto'>
                <p>Note: You can modify the text for better layout. This is a preview of your resume. To download the PDF, click the "Download PDF" button below.</p>
            </div>
            <div className='mt-10'>
                <div className='flex justify-center max-w-[60%] mx-auto gap-4 items-center max-md:flex-col'>
                    <select onChange={(e) => setSelectedFont(e.target.value)} className='rounded-full px-4 py-1 numo-outer-btn-200'>
                        {fontArray.map((font, index) => (
                            <option key={index} value={font.value} className={selectedFont === font.value ? 'outline outline-1 outline-primary' : ''}>
                                {font.label}
                            </option>
                        ))}
                    </select>
                    <select onChange={(e) => setDesign(e.target.value)} className='rounded-full px-4 py-1 numo-outer-btn-200'>
                        {resumeDesignTypes.map((designType, index) => (
                            <option key={index} value={designType.id} className={design === designType.id ? 'outline outline-1 outline-primary' : ''}>
                                {designType.label}
                            </option>
                        ))}
                    </select>
                    {design === 'professional' && (
                        <select defaultValue="false" onChange={(e) => handlePositionChange(e.target.value)} className='rounded-full px-4 py-1 numo-outer-btn-200'>
                            <option value="true" >Full Width Project</option>
                            <option value="false" >Right Aligned Project</option>
                        </select>
                    )}
                </div>
            </div>

            <div className='flex justify-center mb-10 mt-10 max-w-full gap-2'>
                <Button type="button" name={'Edit'} class="w-40" onClick={() => navigate('/create-cv')} />
                <Button
                    type="button"
                    name={purchaseCompleted ? 'Download PDF' : 'Download'}
                    class="w-40"
                    onClick={handleDownloadInChild}
                />
            </div>
        </>
    );
};

export default ViewCv;
